header {
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 11;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, .1);
  @media(max-width: 767px) {
    box-shadow: none;
  }
}

.container-header {
  @include container;
}

.menu-mobile {
  display: none;
  @media(max-width: 767px) {
    display: block;
  }
}

.region-header {
  //@include span(10 of 12);
  //margin: 0 auto;
  //float: none;
  padding-left: span(1 of 12) + gutter();
  padding-right: span(1 of 12) + gutter();
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  //align-items: center;
  @media(max-width: 767px) {
    padding: 0;
  }
  #block-wps-sitebranding {
    float: left;
    padding-top: 13px;
    padding-bottom: 7px;
    @media(max-width: 1023px) {
      float: none;
      margin: 0 auto 10px auto;
    }
    @media(max-width: 767px) {
      margin: 0 auto 0 auto;
      padding:  10px 0;
      width: 100%;
      z-index: 102;
      background: #fff;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, .1);
    }
    .block-content {
      @media(max-width: 767px) {
        display: flex;
        justify-content: center;
      }
    }
    .site-logo {
      display: block;
      height: 60px;
      @media(max-width: 767px) {
        height: 40px;
      }
      img {
        @media(max-width: 767px) {
          display: block;
          max-width: 100%;
          width: auto;
          height: 40px;
        }
      }
    }
  }
  nav.navigation {
    float: right;
    &.logged-in {
      align-self: center;
      >span {
        margin-right: 10px;
      }
    }
    @media(max-width: 767px) {
      padding: 10px $mobile_container_padding 20px $mobile_container_padding;
      display: flex;
      //visibility: hidden;
      position: absolute;
      top: 60px;
      background-color: #0a5f73;
      background-image: url("../images/responsive-bg.png");
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      text-align: center;
      width: 100%;
      z-index: 101;
      height: 100%;
      left: -100%;
      flex-direction: column;
      @include transition(left, 0.3s, ease-in-out);
    }
    ul.menu {
      padding: 0;
      margin: 0;
      @media(max-width: 767px) {
        margin-top: 15px;
        flex: 1;
        max-height: 350px;
      }
      @media(max-height: 480px) {
        margin-top: 0;
        max-height: 270px;
        flex: none;
        margin-bottom: 20px;
      }
      li {
        list-style-type: none;
      }
      >li {
        float: left;
        margin: 0 0 0 25px;
        padding: 0;
        @media(max-width: 767px) {
          width: 100%;
          margin: 15px 0 15px 0;
        }
        @media(max-height: 480px) {
          margin: 8px 0 8px 0;
        }
        &:first-child {
          margin-left: 10px;
          @media(max-width: 767px) {
            margin: 15px 0 15px 0;
          }
          @media(max-height: 480px) {
            margin: 8px 0 8px 0;
          }
        }
        &:hover, &.menu-item--active-trail {
          a {
            text-shadow: 0 0 0 #0a5f73, 0 0 0 #0a5f73;
            border-bottom: 4px solid $main_yellow;
            @media(max-width: 767px) {
              border-bottom: none;
              color: $main_yellow;
            }
          }
        }
        a {
          text-decoration: none;
          display: block;
          font-size: 12px;
          font-weight: 500;
          color: #0a5f73;
          padding: 0 0 12px 0;
          border-bottom: 4px solid #fff;
          letter-spacing: .1em;
          transition: all .3s;
          @media(max-width: 767px) {
            color: #fff;
            font-size: 20px;
            border-bottom: none;
            text-align: center;
            width: 100%;
            padding: 0;
          }
          @media(max-height: 480px) {
            font-size: 16px;
          }
        }
      }
    }
    .follow-links-navigation {
      display: none;
      @media(max-width: 767px) {
        display: block;
        padding-bottom: 40px;
      }
      .follow-us-contact {
        >span {
          color: #fff;
          text-align: center;
          width: 100%;
          display: block;
          margin: 0 0 15px 0;
        }
        a {
          margin: 0 10px;
          &.follow-linkedin, &.follow-twitter {
            width: 42px;
            height: 42px;
            background-repeat: no-repeat;
            background-size: 42px 42px;
            display: inline-block;
            img {
              display: none;
            }
          }
          &.follow-linkedin {
            background-image: url("../svg/social/Linkedin_icon_white.svg");
          }
          &.follow-twitter {
            background-image: url("../svg/social/Twitter_icon_white.svg");
          }
        }
      }
    }
  }
}

body.toolbar-horizontal.user-logged-in {
  header {
    top: 78px;
  }
}

body.menu-open {
  @media(max-width: 767px) {
    overflow:hidden;
  }
  //header {
  //  @media(max-width: 767px) {
  //    height: 100%;
  //    background: transparent;
  //  }
  //}
  .region-header nav.navigation {
    @media(max-width: 767px) {
      visibility: visible;
      left: 0;
    }
  }
}
