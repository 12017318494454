body.welcome_screen {
  .paragraph--type--hero .bordered-wrapper .bottom-border:after {
    display: none;
  }
}

#paragraph-testimonial-welcome {
  &.paragraph--type--testimonials.testimonial-vertical .vertical-testimonials:before {
    display: none;
  }
  &.paragraph--type--testimonials.testimonial-vertical .vertical-testimonials:after {
    display: none;
  }
  &.paragraph--type--testimonials.testimonial-vertical .vertical-testimonials .swiper-testimonials-wrapper .field__item {
    margin-bottom: 30px;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    &:first-child {

    }
    &:nth-child(2) {

    }
    &:nth-child(3) {

    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  .testimonials-actions {
    @include full;
    text-align: center;
    padding: 40px 30px;
    a {
      margin: 0 10px 10px 10px;
    }
  }
  &.testimonials-left {
    .vertical-testimonials .swiper-testimonials-wrapper .field__item {
      margin-bottom: 0 !important;
      &:nth-child(1) {
        .sw-slide-text {
          padding-bottom: 50px;
        }
      }
      &:nth-child(2n) {
        .sw-slide-text {
          float: left;
          order: 1;
        }
      }
      .sw-slide-text {
        //padding: 20px 0;
      }
    }
  }
  .hello-container {
    text-align: center;
    .helo-name {
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
}

.full-page-loader {
  width: 100%;
  height: 500px;
  position: relative;
  text-align: center;
}

.app-error-container {
  max-width: 760px;
  margin: 60px auto 20px auto;
  text-align: center;
}

.page-actions {
  margin: 0 auto;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  .button {
    margin: 0 10px;
  }
}

body {
  &.getting_to_know_you_v2, &.getting_to_know_you, &.who_am_i, &.who_am_i_follow_up {
    .layout-content {
      min-height: calc(100vh - 80px - 177px);
    }
  }
}


.sw-checkboard-svg {
  max-width: 400px;
  float: right;
  margin-top: -210px;
  z-index: 2;
  position: relative;
  margin-right: 8.62069%;
  margin-bottom: 150px;
  @media (max-width: 820px) {
    margin-top: -230px;
    max-width: 350px;
    margin-bottom: 80px;
  }
  @media (max-width: 760px) {
    margin: 50px auto;
    float: none;
    display: block;
  }
}

#paragraph-testimonial-welcome.welcome-who-am-i .vertical-testimonials .swiper-testimonials-wrapper .field__item:nth-child(1) .sw-slide-text {
  @media (max-width: 820px) {
    width: 100%;
    max-width: 100%;
  }
}

