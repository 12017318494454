.field--name-field-block {
  overflow: hidden;
}
.view-team-members {
  @include container;
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  @media(max-width: 767px) {
    margin: 25px 0;
  }
  &:before, &:after {
    content: "";
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    background-color: #F5F5F5;
    height: 385px;
  }
  &:before {
    left: -100%;
  }
  &:after {
    right: -100%;
  }
  .swiper-team {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    width: 100%;
    &.swiper-container-autoheight {
      height: auto;
      .view-content {
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-transition-property: height,-webkit-transform;
        transition-property: height,-webkit-transform;
        -o-transition-property: transform,height;
        transition-property: transform,height;
        transition-property: transform,height,-webkit-transform;
      }
    }
  }
  .view-content {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
  .views-row {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    //height: 100%;
    position: relative;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform;
  }
  .swiper-pagination {
    display: none;
    @media(max-width: 767px) {
      display: block;
      position: static;
      top: 0;
      bottom: initial;
      max-width: 50%;
      margin: 0 auto;
      padding: 20px 0;
    }
    .swiper-pagination-current {
      font-weight: 500;
    }
  }
  .swiper-button-prev {
    background-image: url('../svg/swiper/arrow_back.svg');
    background-size: 15px 30px;
    width: 15px;
    height: 30px;
    margin-top: -22px;
    top: 200px;
    @media(max-width: 767px) {
      margin-top: 0;
      top: 4px;
      left: 0;
      width: 50px;
      height: 50px;
    }
  }
  .swiper-button-next {
    background-image: url('../svg/swiper/arrow_forward.svg');
    background-size: 15px 30px;
    width: 15px;
    height: 30px;
    margin-top: -22px;
    top: 200px;
    @media(max-width: 767px) {
      margin-top: 0;
      top: 4px;
      right: 0;
      width: 50px;
      height: 50px;
    }
  }
}

.node--type-team-member {
 &.node--view-mode-teaser {
   float: left;
   width: 100%;
   .node__content {
     float: left;
     width: 100%;
   }
   .image {
     text-align: center;
     background-color: #F5F5F5;
     height: 385px;
     position: relative;
     margin: 0 0 40px 0;
     display: flex;
     align-items: flex-end;
     justify-content: center;
     @media(max-width: 767px) {
       margin-bottom: 15px;
     }
     &:after {
       content: "";
       position: absolute;
       right: -40px;
       top: 0;
       width: 40px;
       height: 100%;
       background-color: #F5F5F5;
     }
     img {
       margin: 0 auto;
       display: block;
     }
   }
   .member-details {
     padding: 0 20px;
     float: left;
     width: 100%;
     .contact {
       float: left;
       width: 100%;
       @media(max-width: 767px) {
         font-size: 16px;
       }
     }
   }
   h2.full-name {
     font-size: 30px;
     letter-spacing: 0.1px;
     font-weight: 300;
     margin-bottom: 20px;
     float: left;
     width: 100%;
     @media(max-width: 767px) {
       font-size: 20px;
       margin-bottom: 10px;
     }
   }
   .field--name-field-job-title {
     font-size: 18px;
     font-weight: 500;
     margin: 0 0 25px 0;
     float: left;
     width: 100%;
     @media(max-width: 767px) {
       margin-bottom: 15px;
     }
   }
   .field--name-field-description {
     border-bottom: 2px solid $main_yellow;
     margin: 0 0 20px 0;
     padding: 0 0 20px 0;
     p {
       font-size: 18px;
       @media(max-width: 767px) {
         font-size: 16px;
       }
     }
   }
 }
}
