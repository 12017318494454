.site-hero {
  //float: none;
  //display: block;
  //width: 100%;
  //margin: 0 auto;
  //overflow: hidden;
  .empty-hero {
    @include full;
    margin: 0 0 20px 0;
    height: 150px;
    @media(max-width: 500px) {
      height: 75px;
    }
  }
}

.field--name-field-hero {
  .swiper-wrapper {
    .paragraph-slide {
      height: 100%;
      width: 100%;
    }
  }
}

.paragraph--type--hero {
  width: 100%;
  overflow: hidden;
  position: relative;
  .text-wrapper {
    @include container;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 3;
    color: #fff;
    text-align: center;
    justify-content: center;
    h1 {
      margin: 0;
      animation-name: css-animation;
      animation-duration: 2s;
      animation-iteration-count: 1;
      animation-direction: alternate;
      animation-timing-function: linear;
      color: $main_text_colour;
      @media (max-width: 1000px) {
        font-size: 40px
      }
      @media (max-width: 470px) {
        font-size: 24px;
      }
    }
  }
  .bordered-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    @media(max-width: 860px) {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
    @media(max-width: 767px) {
      padding-left: 40px;
      padding-right: 40px;
    }
    .top-border {
      height: 120px;
      margin: 0 auto;
      //border-top: 4px solid $main_yellow;
      //border-left: 4px solid $main_yellow;
      //border-right: 4px solid $main_yellow;
      position: relative;
      display: block;
      width: 715px;
      background-repeat: no-repeat;
      background-image: linear-gradient(to right, $main_yellow 100%, $main_yellow 100%),
      linear-gradient(to bottom, $main_yellow 100%, $main_yellow 100%),
      linear-gradient(to right, $main_yellow 100%, $main_yellow 100%),
      linear-gradient(to bottom, $main_yellow 100%, $main_yellow 100%);
      background-size: 0;
      background-position: 0 0,
      100% 0,
      100% 100%,
      0 100%;
      animation: bgLeftTopRight 1s linear;
      animation-delay: 0.5s;
      animation-fill-mode: forwards;
      //animation-play-state: paused;
      //&:hover {
      //  animation-play-state: running;
      //}

      @media(max-width: 860px) {
        height: 60px;
        max-width: 100%;
      }
      @media(max-width: 767px) {
        height: 40px;
        //border-top: 2px solid $main_yellow;
        //border-left: 2px solid $main_yellow;
        //border-right: 2px solid $main_yellow;
        margin-bottom: -10px;
      }
    }
    .bottom-border {
      height: 120px;
      margin: 0 auto;
      //border-bottom: 4px solid $main_yellow;
      //border-left: 4px solid $main_yellow;
      //border-right: 4px solid $main_yellow;
      position: relative;
      display: block;
      width: 715px;

      background-repeat: no-repeat;
      background-image: linear-gradient(to right, $main_yellow 100%, $main_yellow 100%),
      linear-gradient(to bottom, $main_yellow 100%, $main_yellow 100%),
      linear-gradient(to right, $main_yellow 100%, $main_yellow 100%),
      linear-gradient(to bottom, $main_yellow 100%, $main_yellow 100%);
      background-size: 0;
      background-position: 0 0,
      100% 0,
      100% 100%,
      0 100%;
      animation: bgRightBottomLeft 1s linear;
      animation-delay: 1.5s;
      animation-fill-mode: forwards;
      //animation-play-state: paused;
      //&:hover {
      //  animation-play-state: running;
      //}

      @media(max-width: 860px) {
        height: 60px;
        max-width: 100%;
      }
      @media(max-width: 767px) {
        height: 40px;
        //border-bottom: 2px solid $main_yellow;
        //border-left: 2px solid $main_yellow;
        //border-right: 2px solid $main_yellow;
        margin-top: -10px;
      }
      &:after {
        content: "";
        position: absolute;
        background: url('../../images/svg/hero_Quill_Candle_image2.svg') no-repeat;
        width: 240px;
        height: 135px;
        background-size: 240px 135px;
        right: -130px;
        bottom: -30px;
        @media(max-width: 1000px) {
          content: none;
        }
      }
    }
  }
  .field--name-field-text {
    width: 100%;
    padding: 25px 0;
    position: relative;
    @media(max-width: 767px) {
      padding: 0 24px;
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .field--name-field-image-single {
    display: block;
    position: relative;
    z-index: 2;
    img {
      display: none;
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }

  .hero-image-bg {
    width: 100%;
    position: relative;
    //height: 65vh;
    height: 515px;
    @media(max-width: 1000px) {
      height: 400px;
    }
    @media(max-width: 767px) {
      height: 200px;
    }
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  &.bg-offset-top {
    .hero-image-bg {
      background-position: 50% 10%;
    }
  }

}


@keyframes css-animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@keyframes bgLeftTopRight {
  0% {
    background-size:    0 2px,
    2px 0,
    0 2px,
    2px 0;
  }
  25% {
    background-size:    0 2px,
    2px 0,
    0 2px,
    2px 100%;
  }
  50% {
    background-size:    100% 2px,
    2px 0,
    0 2px,
    2px 100%;
  }
  75% {
    background-size:    100% 2px,
    2px 100%,
    0 2px,
    2px 100%;
  }
  100% {
    background-size: 100% 2px,
    2px 100%,
    0 2px,
    2px 100%;
  }
}

@keyframes bgRightBottomLeft {
  0% {
    background-size:    0 2px,
    2px 0,
    0 2px,
    2px 0;
  }
  25% {
    background-size:    0 2px,
    2px 100%,
    0 2px,
    2px 0;
  }
  50% {
    background-size:    0 2px,
    2px 100%,
    100% 2px,
    2px 0;
  }
  75% {
    background-size:    0 2px,
    2px 100%,
    100% 2px,
    2px 100%;
  }
  100% {
    background-size:    0 2px,
    2px 100%,
    100% 2px,
    2px 100%;
  }
}

body {
  &.ms-browser {
    .paragraph--type--hero .bordered-wrapper .top-border {
      animation: none;
      background-size: 100% 2px,
      2px 100%,
      0 2px,
      2px 100%;
    }
    .paragraph--type--hero .bordered-wrapper .bottom-border {
      animation: none;
      background-size:    0 2px,
      2px 100%,
      100% 2px,
      2px 100%;
    }
  }
}


//.paragraph--type--hero .bordered-wrapper .bottom-border, .paragraph--type--hero .bordered-wrapper .top-border {
//  @media (max-width: 1000px) {
//    height: 80px;
//    width: 100%;
//  }
//}
