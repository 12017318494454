.button {
  border-radius: 8px;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.1px;
  text-align: center;
  padding: 18px 10px;
  border: 2px solid $main_text_colour;
  background: transparent;
  color: $main_text_colour;
  text-decoration: none;
  display: inline-block;
  @include transition();
  width: 300px;
  max-width: 100%;
  cursor: pointer;
  @media(max-width: 767px) {
    font-size: 16px;
    padding: 16px 8px;
  }
  &:hover {
    color: #fff;
    background-color: $main_text_colour;
    text-decoration: none;
  }
  &.button-yellow {
    border-radius: 8px;
    background-color: $main_yellow;
    border: 2px solid $main_yellow;
    color: #fff;
    text-decoration: none;
    &:hover {
      background-color: transparent;
      color: $main_yellow;
      text-decoration: none;
    }
  }
  &.button-disabled {
    cursor: default;
    background-color: #cccccc;
    border: 2px solid #999999;
    color: #666666;
    &:hover {
      background-color: #cccccc;
      border: 2px solid #999999;
      color: #666666;
    }
  }
  &.button-blue {
    color: #fff;
    background-color: $main_text_colour;
    &:hover {
      border: 2px solid $main_text_colour;
      color: $main_text_colour;
      background: transparent;
    }
  }
  &.small {
    width: auto;
    min-width: 120px;
    padding: 9px 5px;
  }
  &.button-full {
    width: 100%;
  }
}
