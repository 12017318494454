.paragraph--type--testimonials {
  @include container;
  // Vertical
  &.testimonial-vertical {
    .testimonials-container {
      float: left;
      width: 100%;
      padding-top: 50px;
      padding-bottom: 50px;
      @media(max-width: 767px) {
        padding-top: 25px;
        padding-bottom: 25px;
      }
      >h2 {
        padding: 0 span(1 of 12) + gutter();
        @media(max-width: 767px) {
          padding: 0 $mobile_container_padding;
          text-align: center;
        }
      }
    }
    .vertical-testimonials {
      padding: 90px span(1 of 12) + gutter();
      background: #f5f5f5;
      float: left;
      width: 100%;
      position: relative;
      @media(max-width: 767px) {
        background: #fff;
        padding: 25px $mobile_container_padding;
        display: flex;
        flex-direction: column;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 200px;
        bottom: -100px;
        background-image: url('../images/illustrations/svg/CTA_Phone_illustration.svg');
        width: 278px;
        height: 352px;
        background-repeat: no-repeat;
        @media(max-width: 1023px) {
          left: 24px;
        }
        @media(max-width: 767px) {
          left: 0;
          position: static;
          height: 215px;
          margin: 0 auto;
        }
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        right: 200px;
        top: -100px;
        background-image: url('../images/illustrations/svg/balloon_Illustration.svg');
        width: 278px;
        height: 352px;
        background-repeat: no-repeat;
        @media(max-width: 1023px) {
          right: 24px;
        }
        @media(max-width: 767px) {
          display: none;
        }
      }
      .swiper-testimonials-wrapper {
        .field__item {
          @include full;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .sw-slide-text {
            @include span(5 of 12);
            margin-right: 0;
            clear: both;
            position: relative;
            order: 1;
            @media(max-width: 767px) {
              @include full;
              margin: 25px 0;
              order: 3;
            }
            h2 {
              @media(max-width: 767px) {
                font-size: 16px;
              }
            }
            &:before {
              content: "";
              width: 2px;
              background-color: $main_yellow;
              height: 100%;
              display: block;
              position: absolute;
              left: -14px;
              top: 0;
              @media(max-width: 767px) {
                display: none;
              }
            }
          }
          .sw-slide-image {
            @include span(5 of 12);
            margin-right: 0;
            float: right;
            max-height: 200px;
            order: 2;
          }

          &:nth-child(2n) {
            .sw-slide-text {
              float: right;
              order: 2;
            }
            .sw-slide-image {
              float: left;
              order: 1;
            }
          }
        }
      }
    }
  }
  // Slider
  &.testimonial-slider {
    .testimonials-container {
      padding-top: 50px;
      padding-bottom: 50px;
      position: relative;
      @media(max-width: 767px) {
        padding-top: 25px;
        padding-bottom: 75px;
      }
      >h2 {
        text-align: center;
      }
      .swiper-testimonials-containers {
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        list-style: none;
        padding: 0;
        z-index: 1;
        width: 100%;
        padding-bottom: 50px;
        &.swiper-container-autoheight {
          height: auto;
          .swiper-testimonials-wrapper {
            -webkit-box-align: start;
            -webkit-align-items: flex-start;
            -ms-flex-align: start;
            align-items: flex-start;
            -webkit-transition-property: height,-webkit-transform;
            transition-property: height,-webkit-transform;
            -o-transition-property: transform,height;
            transition-property: transform,height;
            transition-property: transform,height,-webkit-transform;
          }
        }
        .swiper-testimonials-wrapper {
          -webkit-transform: translate3d(0,0,0);
          transform: translate3d(0,0,0);
          position: relative;
          width: 100%;
          height: 100%;
          z-index: 1;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-transition-property: -webkit-transform;
          transition-property: -webkit-transform;
          -o-transition-property: transform;
          transition-property: transform;
          transition-property: transform,-webkit-transform;
          -webkit-box-sizing: content-box;
          box-sizing: content-box;
          .sw-slide {
            -webkit-flex-shrink: 0;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            width: 100%;
            //height: 100%;
            position: relative;
            -webkit-transition-property: -webkit-transform;
            transition-property: -webkit-transform;
            -o-transition-property: transform;
            transition-property: transform;
            transition-property: transform,-webkit-transform;
            padding-left: span(1 of 12) + gutter();
            padding-right: span(1 of 12) + gutter();
          }
        }
        .swiper-testimonials-wrapper {
          p {
            @media(max-width: 767px) {
              font-size: 20px;
            }
            &.tiny {
              @media(max-width: 767px) {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  // Swiper styles
  .swiper-pagination {
    bottom: 0;
    >span {
      &.swiper-pagination-bullet {
        padding: 10px 15px;
        margin: 0;
        background: transparent;
        width: auto;
        height: auto;
        font-size: 18px;
        opacity: 1;
        font-weight: 300;
      }
      &.swiper-pagination-bullet-active {
        font-weight: 500;
      }
    }
  }
  .swiper-button-prev {
    background-image: url('../svg/swiper/arrow_back.svg');
    background-size: 15px 30px;
    width: 30px;
    height: 100%;
    margin-top: 0;
    top: 0;
    @media(max-width: 767px) {
      display: none;
    }
  }
  .swiper-button-next {
    background-image: url('../svg/swiper/arrow_forward.svg');
    background-size: 15px 30px;
    width: 30px;
    height: 100%;
    margin-top: 0;
    top: 0;
    @media(max-width: 767px) {
      display: none;
    }
  }
}

