.expanded-view-display {
  .paragraph--type--expandable-slider {
    .expanded-view {
      display: block;
    }
    .not-expanded-view {
      display: none;
    }
  }
}
.expandable-slider-container {
  @include container;
  position: relative;
  //@media(max-width: 767px) {
  //  padding: 0 $mobile_container_padding;
  //}
  .responsive-border {
    display: none;
    @media(max-width: 767px) {
      display: block;
      @include full;
      padding: 0 $mobile_container_padding;
      background: #E2EEEE;
      span.responsive-border-1 {
        display: block;
        width: 100%;
        border-top: 2px solid #0a5f73;
        position: relative;
      }
      span.responsive-border-2 {
        display: block;
        width: 100%;
        height: 3px;
        //border: 1px dashed #ccc;
        position: relative;
        border-radius: 5px;
        margin: 25px 0 0 0;
      }
    }
  }
  .swiper-pagination-bg {
    background: #E2EEEE;
    height: 220px;
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    @include beforeAfterColor(#E2EEEE);
    @media(max-width: 767px) {
      display: none;
    }
  }
  .expandable-slider-wrapper {
    overflow: hidden;
    padding-top: 20px;
    padding-bottom: 50px;
    position: relative;
    @media(max-width: 767px) {
      padding: 0 0 35px 0;
      display: flex;
      flex-direction: column;
    }
    .expandable-slider-items {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      align-items: flex-start;
      -webkit-transition-property: -webkit-transform;
      transition-property: -webkit-transform;
      -o-transition-property: transform;
      transition-property: transform;
      transition-property: transform,-webkit-transform;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      .paragraph--type--expandable-slider {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        width: 100%;
        height: auto;
        position: relative;
        -webkit-transition-property: -webkit-transform;
        transition-property: -webkit-transform;
        -o-transition-property: transform;
        transition-property: transform;
        transition-property: transform,-webkit-transform;
        padding-left: span(1 of 12) + gutter();
        //padding-right: span(1 of 12) + gutter();
        @media(max-width: 767px) {
          flex-direction: column;
          padding: 0 $mobile_container_padding;
        }
      }
    }
  }

  // Swiper styles
  .swiper-pagination {
    @include span(10 of 12);
    padding: 0 0 20px 0;
    position: static;
    bottom: initial;
    top: 15px;
    margin: 0 auto 50px auto;
    float: none;
    border-top: 2px solid #0a5f73;
    display: flex;
    justify-content: space-between;
    background: #E2EEEE;
    @media(max-width: 767px) {
      @include full;
      margin: 0 auto 0 auto;
      border-top: none;
      //border-bottom: 2px solid #0a5f73;
      background: #E2EEEE;
      //max-width: 270px;
    }
    >span {
      text-align: left;
      .span-inner {
        //max-width: 100px;
      }
      &.swiper-pagination-bullet {
        background: transparent;
        width: auto;
        height: auto;
        font-size: 18px;
        opacity: 1;
        font-weight: 300;
        flex: 1;
        margin: 0 span(1 of 12) 0 0;
        padding: 20px 0 0 0;
        position: relative;
        &:before {
          content: "";
          width: 100%;
          position: absolute;
          display: block;
          top: -5px;
          height: 10px;
          background-color: transparent;
          left: 0;
        }
        @media(max-width: 767px) {
          display: none;
          cursor: none;
          pointer-events: none;
        }
      }
      &.swiper-pagination-bullet-active {
        font-weight: 500;
        &:before {
          background-color: $main_yellow;
        }
        @media(max-width: 767px) {
          display: block;
          margin: 0;
          cursor: none;
          pointer-events: none;
          .span-inner {
            width: 100% !important;
            text-align: center;
            display: block;
            margin: 0 auto;
            padding: 0 20px;
            max-width: 285px;
            cursor: none;
            pointer-events: none;
          }
          &:before {
            display: none;
          }
        }
      }
      &:nth-child(1) {
        .span-inner {
          width: 70px;
          display: block;
        }
      }
      &:nth-child(2) {
        .span-inner {
          width: 70px;
          display: block;
        }
      }
      &:nth-child(3) {
        .span-inner {
          width: 115px;
          display: block;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .swiper-button-prev {
    background-image: url('../svg/swiper/arrow_back.svg');
    background-size: 15px 30px;
    width: 40px;
    height: 40px;
    margin-top: -29px;
    top: 30px;
    @media(max-width: 767px) {
      top: 39px;
      left: 12px;
    }
  }
  .swiper-button-next {
    background-image: url('../svg/swiper/arrow_forward.svg');
    background-size: 15px 30px;
    width: 40px;
    height: 40px;
    margin-top: -29px;
    top: 30px;
    @media(max-width: 767px) {
      top: 39px;
      right: 12px;
    }
  }
}

.paragraph--type--expandable-slider {
  .slide-title {
    margin: 0 0 25px 0;
    @media(max-width: 767px) {
      @include full;
      position: relative;
      display: block;
      background: #E2EEEE;
      padding: 25px 0 25px 0;
      font-size: 20px;
      margin: 0 0 15px 0;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -$mobile_container_padding;
        width: $mobile_container_padding;
        height: 100%;
        background-color: #E2EEEE;
      }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: -$mobile_container_padding;
        width: $mobile_container_padding;
        height: 100%;
        background-color: #E2EEEE;
      }
    }
  }
  .expanded-view {
    @include full;
    margin-top: 25px;
    display: none;
    @media(max-width: 767px) {
      margin-top: 0;
    }
  }
  .not-expanded-view {
    @include full;
  }
  .slide-text {
    @include span(4 of 12);
    >.button {
      clear: both;
      margin: 25px 0 0 0;
      @media(max-width: 767px) {
        float: none;
        margin: 25px auto 0 auto;
        display: block;
      }
    }
    @media(max-width: 767px) {
      @include full;
    }
  }
  .field--name-field-text {
    font-size: 18px;
    margin-top: 25px;
    p {
      font-size: 18px;
      @media(max-width: 767px) {
        font-size: 16px;
      }
    }
    @media(max-width: 767px) {
      margin-top: 15px;
    }
  }
  .field--name-field-image {
    @include span(8 of 12 last);
    float: left;
    img {
      margin-top: -105px;
      max-width: 100%;
      height: auto;
      @media(max-width: 767px) {
        margin-top: 30px;
      }
    }
    @media(max-width: 767px) {
      @include full;
    }
  }
  .field--name-field-texts {
    @include full;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media(max-width: 767px) {
      flex-direction: column;
    }
    .field__item {
      flex: 1;
      margin-right: span(1 of 12) + gutter();
      position: relative;
      @media(max-width: 767px) {
        margin-top: 25px;
        margin-right: 0;
        padding-bottom: 25px;
      }
      &:last-child {
        margin-right: 0;
        padding-bottom: 0;
        @media(max-width: 767px) {
          &:after {
            display: none;
          }
        }
      }
      &:after {
        content: "";
        width: 2px;
        background-color: $main_yellow;
        height: 100%;
        display: block;
        position: absolute;
        left: -14px;
        top: 0;
        @media(max-width: 767px) {
          //display: none;
          //position: static;
          width: 50%;
          height: 2px;
          left: 0;
          bottom: 0;
          top: initial;
        }
      }
    }
  }
}
