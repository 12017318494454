.fade-enter {
  .form-step-wrapper {
    .form-step-info, .form-step-fields {
      opacity: 0.01;
      transition: opacity 500ms ease-in;
    }
  }
  //opacity: 0.01;
  .loader-wrapper {
    display: flex;
  }
}
.fade-enter-active {
  .form-step-wrapper {
    .form-step-info, .form-step-fields {
      opacity: 1;
      transition: opacity 500ms ease-in;
    }
  }
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-enter-done {
  .loader-wrapper {
    display: none;
  }
}

.fade-exit {
  opacity: 1;
  transition: opacity 500ms ease-in;
  &.fade-exit-active {
    opacity: 0.01;
  }
}

//.fade-enter {
//  opacity: 0.01;
//  transform: translateX(-100%);
//}
//.fade-enter-active {
//  opacity: 1;
//  transform: translateX(0%);
//  transition: all 300ms ease-out;
//}
//
//.form-step-wrapper.fade-exit {
//  opacity: 1;
//  top: 0;
//  transform: translateX(0%);
//  position: absolute;
//}
//.form-step-wrapper.fade-exit-active {
//  opacity: 0.01;
//  transform: translateX(200%);
//  //transition: all 300ms ease-out;
//  transition-property: opacity, translateX;
//  transition-duration: 9s, 9s;
//  transition-delay: 2s, 0s;
//}



// https://loading.io/css/
.loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(#ccc, 0.3);
  z-index: 20;
  display: none;
  &.force-display {
    display: flex;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
