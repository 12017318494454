.paragraph.paragraph--type--text {
  width: 100%;
 .paragraph-content {
   @include container;
   .paragraph-inner {
     padding-top: 50px;
     padding-bottom: 50px;
     padding-left: span(1 of 12) + gutter();
     padding-right: span(1 of 12) + gutter();
     @media(max-width: 767px) {
       padding: 25px $mobile_container_padding;
     }
     p {
       &:last-child {
         margin-bottom: 0;
       }
     }
   }
 }
  &.yellow-border-top {
    .field--name-field-text {
      border-top: 2px solid $main_yellow;
      padding-top: 60px;
      @media(max-width: 767px) {
        border-top: 2px solid $main_yellow;
        padding-top: 30px;
      }
    }
  }
  &.our-process {
    background: #E2EEEE;
    position: relative;
    margin-top: 50px;
    @media(max-width: 767px) {
      margin-top: 25px;
    }
  }
}


