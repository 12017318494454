.feedback-popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  .feedback-inner {
    border: 2px solid #f7b133;
    padding: 40px 20px 20px 20px;
    background: #fff;
    width: 380px;
    min-height: 305px;
    text-align: center;
    max-width: 100%;
    >h3 {
      font-size: 18px;
      line-height: 21px;
      font-weight: 600;
      margin: 0 auto 5px auto;
    }
    >p {
      font-size: 18px;
      line-height: 21px;
    }
  }
  .feedback-icons-holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .close-feedback {
    display: block;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    //background: #f7b133;
    cursor: pointer;
    background: #f7b133 url('../svg/x.png') no-repeat center;
    margin: -60px auto 20px auto;
  }
  span.feedback-icon {
    display: block;
    width: 76px;
    height: 162px;
    margin: 0 10px;
    cursor: pointer;
    &.feedback-icon-A {
      // background: url('../faces/Smiley-Face-Buttons-A.svg') no-repeat center;
      background: url('../faces/Smiley-Face-Buttons-A.png') no-repeat center;
    }
    &.feedback-icon-B {
      // background: url('../faces/Smiley-Face-Buttons-B.svg') no-repeat center;
      background: url('../faces/Smiley-Face-Buttons-B.png') no-repeat center;
    }
    &.feedback-icon-C {
      // background: url('../faces/Smiley-Face-Buttons-C.svg') no-repeat center;
      background: url('../faces/Smiley-Face-Buttons-C.png') no-repeat center;
    }
    &.is-active-true {
      border: 1px solid #ccc;
    }
  }
}
