footer {
  overflow: hidden;
}

.footer-bottom {
  background-color: #0a5f73;
  .region-footer-bottom {
    @include container;
    font-size: 12px;
    color: #fff;
    padding: 18px 0;
    //@include beforeAfterColor(#0a5f73);
    a {
      color: #fff;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

#block-footercontact {
  .block-content {
    @include container;
    padding: 50px 0 85px 0;
    @media(max-width: 1023px) {
      padding: 30px $mobile_container_padding 55px $mobile_container_padding;
      text-align: center;
    }
    >h2 {
      font-size: 50px;
      background-color: #fff;
      max-width: 295px;
      margin: 0 0 -114px 51px;
      position: relative;
      z-index: 2;
      padding: 0 0 25px 20px;
      font-weight: 300;
      @media(max-width: 1023px) {
        @include full;
        max-width: 100%;
        margin: 0;
        padding: 30px 0;
        text-align: center;
        border-top: 2px solid $main_yellow;
      }
      @media(max-width: 767px) {
        font-size: 30px;
        padding: 30px 0 15px 0;
      }
    }
    .field--name-body {
      @include span(10 of 12);
      margin: 0 auto;
      float: none;
      font-size: 25px;
      border: 2px solid $main_yellow;
      padding: 78px 106px 77px 254px;
      @media(max-width: 1023px) {
        @include full;
        padding: 30px 0 0 0;
        border: none;
        text-align: center;
      }
      @media(max-width: 767px) {
        padding: 15px 0;
      }
      p {
        font-size: 25px;
        @media(max-width: 767px) {
          font-size: 20px;
        }
      }
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
      a {
        font-weight: 500;
      }
    }
    .footer-contact-illustration {
      float: right;
      background-color: #fff;
      padding: 30px 0 0 50px;
      margin: -210px 0 0 0;
      @media(max-width: 1023px) {
        margin: 0 auto;
        padding: 30px 0;
        float: none;
      }
    }
  }
}

#block-footerlogo {
  background: #e2eeee;
  padding: 32px 0;
  @media(max-width: 767px) {
    display: none;
  }
  .block-content {
    @include container;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .field--name-body {
      padding-left: span(1 of 12) + gutter();
      display: flex;
      align-items: center;
      float: left;
      flex: 1;
      @media(max-width: 1023px) {
        padding: 0 $mobile_container_padding;
      }
    }
    .footer-logo {
      float: left;
      margin-right: 42px;
    }
    .footer-text {
      float: left;
      margin: 0;
      max-width: 300px;
    }
  }
  .portal-footer-contact {
    float: right;
    a {
      font-weight: 500;
    }
  }
}

#block-footerinfo {
  float: left;
  margin-right: 15px;
  padding-left: span(1 of 12) + gutter();
  @media(max-width: 1023px) {
    padding: 0 $mobile_container_padding;
  }
  @media(max-width: 767px) {
    @include full;
    text-align: center;
    padding: 0 $mobile_container_padding;
  }
}

#block-wps-buildby {
  float: right;
  @media(max-width: 1170px) {
    padding-right: span(1 of 12) + gutter();
  }
  @media(max-width: 1023px) {
    padding: 0 $mobile_container_padding 0 0;
  }
  @media(max-width: 767px) {
    @include full;
    text-align: center;
    padding: 10px $mobile_container_padding 0 $mobile_container_padding;
  }
  p.build-by {
    margin-bottom: 0;
    font-size: 12px;
    a {
      font-weight: 500;
    }
  }
}

#block-wps-footer {
  float: left;
  >ul.menu {
    margin: 0;
    padding: 0;
    >li.menu-item {
      padding: 0;
    }
  }
}

.pension-transfer {
  width: 100px;
  height: auto;
  display: block;
}
