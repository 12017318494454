.form-summary-wrapper {
  @media (max-width: 600px) {
    text-align: center;
  }
  .summary-row {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
    .summary-row-header {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      @media (max-width: 600px) {
        flex-direction: column;
      }
      >h2 {
        margin: 0 15px 0 0;
        @media (max-width: 600px) {
          margin: 0 0 10px 0;
        }
      }
    }
    .summary-row-cell-wrapper {
      display: flex;
      width: 100%;
      @media (max-width: 800px) {
        flex-direction: column;
        padding-left: 0;
      }
      @media (max-width: 600px) {
        line-height: 30px;
      }
      .summary-row-cel {
        flex: 1;
        &:nth-child(2n - 1){
          padding-right: 30px;
          @media (max-width: 800px) {
            padding-right: 0;
          }
        }
        &:nth-child(2n){
          padding-left: 30px;
          @media (max-width: 800px) {
            padding-left: 0;
          }
        }
        .summary-row-cell-details {
          display: flex;
          margin-bottom: 15px;
          @media(max-width: 600px) {
            flex-direction: column;
          }
          strong {
            flex: 1;
            font-weight: 500;
            padding-right: 20px;
            @media (max-width: 600px) {
              padding: 0;
            }
          }
          span {
            flex: 1;
            word-break: break-word;
          }
        }
      }
      &.summary-row-cell-dependants {
        display: flex;
        .summary-dependants-inner {
          flex: 1;
          display: flex;
          flex-direction: column;
          max-width: 400px;
          padding-right: 40px;
          >div {
            //flex: 1;
            display: flex;
            margin-bottom: 15px;
            strong {
              flex: 1;
              padding-right: 10px;
            }
            span {
              flex: 1;
            }
          }
        }
      }
    }
    //&.life-after-work {
    //  .summary-row-cell-wrapper {
    //    .summary-row-cel {
    //      display: flex;
    //    }
    //  }
    //}
  }
}

.summary-separator {
  border-top: 3px solid #f7b133;
  width: 100%;
  padding-bottom: 50px;
}

.summary-bottom {
  text-align: center;
  border-top: 3px solid $main_yellow;
  padding-top: 30px;
  margin-top: 30px;
  .summary-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 600px;
    align-content: center;
    margin: 0 auto;
    .button {
      margin: 0 10px;
    }
  }
}

// Spending row
.summary-row-cell-wrapper-spending {
  display: block;
  width: 100%;
  @media (max-width: 600px) {
    overflow: scroll;
    max-width: 100%;
  }
  .spending-section {
    width: 100%;
    //margin-bottom: 25px;
    flex: 1;
    @media (max-width: 600px) {
      width: 430px;
    }
    &:first-child, &:last-child {
      .spending-section-row {
        &.spending-section-header {
          .row-1, .row-2, .row-3 {
            padding-top: 25px;
            padding-bottom: 25px;
          }
          .row-2, .row-3 {
            font-size: 25px;
            text-transform: uppercase;
            font-weight: 500;
          }
          @media (max-width: 600px) {
            .row-1, .row-2, .row-3 {
              font-size: 15px;
            }
            .row-1 {
              text-align: left;
            }
            .row-2 {
              padding-left: 15px;
              flex: 1;
            }
            .row-3 {
              padding-left: 12px;
              padding-right: 11px;
            }
          }
        }
      }
    }
    .spending-section-row {
      width: 100%;
      display: flex;
      //margin-bottom: 15px;
      .row-1, .row-2,  .row-3 {
        padding: 7px 0;
        @media (max-width: 600px) {
          font-size: 15px;
          flex: 3;
        }
      }
      .row-1 {
        flex: 3;
        font-weight: 500;
        padding-right: 20px;
        @media (max-width: 600px) {
          text-align: left;
        }
      }
      .row-2 {
        flex: 1;
        @media (max-width: 600px) {
          margin: 0 10px;
        }
      }
      .row-3 {
        flex: 2;
        background-color: #ececec;
        padding-left: 25px;
        @media (max-width: 600px) {
          padding-left: 15px;
        }
      }
      &.spending-section-header {
        //margin-bottom: 25px;
        .row-1, .row-2, .row-3 {
          //padding-top: 25px;
          //padding-bottom: 25px;
          padding-top: 0;
          padding-bottom: 0;
          @media (max-width: 600px) {
            padding-top: 15px;
            padding-bottom: 15px;
          }
        }
        .row-1 {
          font-size: 25px;
          @media (max-width: 600px) {
            font-size: 18px;
          }
        }
        .row-2, .row-3 {
          font-size: 0;
          text-transform: uppercase;
          font-weight: 500;
        }
      }
    }
  }
}
