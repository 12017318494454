.notifications-test {
  padding-bottom: 20px;
  .notification-element {
    padding: 20px;
    border-bottom: 2px solid $main_yellow;
  }
  .notification-number {
    padding: 15px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
  }
}
