@import "~susy/sass/susy";

/**
 * Susy 2 settings
 * Summary width 1200px
 */
$susy: (
  flow: ltr,
  math: fluid, //static
  output: float, //isolate
  gutter-position: after,
  container: auto,
  container-position: center,
  columns: 12,
  gutters: 40px/60px,
  column-width: 60px,
  global-box-sizing: border-box,
  last-flow: to,
  debug: (
    image: false,
    color: rgba(#66f, .25),
    output: background,
    toggle: top right,
  ),
  use-custom: (
    background-image: true,
    background-options: false,
    box-sizing: true,
    clearfix: false,
    rem: true,
  )
);

@import "variables";
@import "fonts";
@import "forms/forms";
@import "pages";

/**
 * Base layout settings
 */

*, ::after, ::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {

}

body {
  font-family: $fontDefault;
  font-size: $basic_font_size_px;
  line-height: 1.4;
  //letter-spacing: 0.5px;
  font-weight: 300;
  text-align: left;
  color: $main_text_colour;
  margin: 0;
  height: 100%;
  background: #fff none;
  padding: 80px 0 0 0;
  @media(max-width: 842px) {
    padding-top: 122px;
  }
  @media(max-width: 767px) {
    padding-top: 60px;
  }
}
