/**
 * Variables
 */
$fontRubik:'Rubik', sans-serif; // 300-light, 400-regular, 500-medium, 700-bold
$fontDefault: $fontRubik;
$basic_font_size: 18 !default;
$basic_font_size_px: #{$basic_font_size}px;
$container_padding: 20px;
$main_text_colour: #0a5f73;
$main_yellow: #f7b133;

$mobile_container_padding: 24px;
