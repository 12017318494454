body.page-node-type-page {
  .body-container {
    @include container;
    .body-content {
      padding-left: span(1 of 12) + gutter();
      padding-right: span(1 of 12) + gutter();
      padding-top: 50px;
      padding-bottom: 50px;
      @media(max-width: 767px) {
        padding-bottom: 25px;
        padding-left: $mobile_container_padding;
        padding-right: $mobile_container_padding;
      }
      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
      h2 {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
