$line-width: 43px;
$line-width-mobile: 20px;

.hamburger {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 25px;
  width: $line-width;
  z-index: 102;
  @include transition(padding-right,0.3s,linear);
  @media(max-width: 767px) {
    display: block;
  }
  .line {
    width: $line-width;
    height: 2px;
    background-color: $main_yellow;
    display: block;
    margin: 8px 0;
    @include transition(all,0.3s, ease-in-out);
  }
  .inner {
    width: $line-width;
    margin: 0 auto;
    -webkit-transform-style: preserve-3d;
    transition: all .5s ease;
  }
  &:hover {

  }
  &.is-active {
    .inner {
      .line:nth-child(2){
        opacity: 0;
      }
      .line:nth-child(1){
        -webkit-transform: translateY(13px) rotate(45deg);
        -ms-transform: translateY(13px) rotate(45deg);
        -o-transform: translateY(13px) rotate(45deg);
        transform: translateY(13px) rotate(45deg);
      }
      .line:nth-child(3){
        -webkit-transform: translateY(-8px) rotate(-45deg);
        -ms-transform: translateY(-8px) rotate(-45deg);
        -o-transform: translateY(-8px) rotate(-45deg);
        transform: translateY(-8px) rotate(-45deg);
      }
      .line {
        background-color: $main_yellow;
      }
    }
  }
}

//body.window-scrolled {
//  #layout-sidebar {
//    .hamburger {
//      display: block;
//    }
//  }
//}
//
//
//body.toolbar-horizontal {
//  .hamburger {
//    top: 40px + $toolbar_horizontal_height;
//    @media(max-width: 1080px) {
//      top: 40px;
//      display: none;
//    }
//  }
//}
//
////second hamburger
//header {
//  .hamburger {
//    display: none;
//    position: absolute;
//    right: 0;
//    top: 10px;
//    width: 70px;
//    left: auto;
//    @media(max-width: 1080px) {
//      display: block;
//      padding: 0 20px 0 0;
//      .inner {
//        float: right;
//        margin: 0;
//      }
//    }
//    .line {
//      background-color: $color_white;
//    }
//  }
//}
//
