@import "pages/home";
@import "pages/portal";

.container {
  @include container;
  &.container-centered {
    text-align: center;
  }
  &.container-blue {
    background: #e2eeee;
  }
  &.container-margin {
    margin: 0 auto 60px auto;
  }
  &.container-padding {
    padding: 60px 20px;
  }
  .container-inner {
    &.container-inner-max600 {
      max-width: 600px;
      margin: 0 auto;
    }
  }
}

.max-width-760 {
  max-width: 600px;
  margin: 0 auto;
}

.error-page {
  text-align: center;
  margin: 0 auto 50px auto;
}


.flex-center-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

body.register_confirmation_complete {
  .login-form-wrapper {
    width: 100%;
    text-align: left;
    max-width: 440px;
    margin: 0 auto;
    float: none;
    >h2, >p.tiny {
      display: none;
    }
  }
}


.section-container-thank-you {
  margin: 40px 0 100px 0;
}

@import "pages/notifications_test";
