@mixin border-radius($radius: 10px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin border-radius2($tl: none, $tr: none, $bl: none, $br: none) {

  @if $bl != none {
    -webkit-border-bottom-right-radius: $br;
    -moz-border-radius-bottomright: $br;
    border-bottom-right-radius: $br;
  }

  @if $bl != none {
    -webkit-border-bottom-left-radius: $bl;
    -moz-border-radius-bottomleft: $bl;
    border-bottom-left-radius: $bl;
  }

  @if $tl != none {
    -webkit-border-top-left-radius: $tl;
    -moz-border-radius-topleft: $tl;
    border-top-left-radius: $tl;
  }

  @if $tr != none {
    -webkit-border-top-right-radius: $tr;
    -moz-border-radius-topright: $tr;
    border-top-right-radius: $tr;
  }
}

@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
  -webkit-text-stroke-width: 0.2px;
}

@mixin transition($property: all, $duration: .2s, $function: ease, $delay: 0s) {
  -webkit-transition: $property $duration $function $delay;
  -moz-transition: $property $duration $function $delay;
  -ms-transition: $property $duration $function $delay;
  -o-transition: $property $duration $function $delay;
  transition: $property $duration $function $delay;
}

@mixin transition-reset() {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}

@mixin border-radius-reset() {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

@mixin box-shadow-reset() {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

@mixin box-shadow($blur_size: 20px, $x_offset: 0px, $y_offset: 2px, $color: #666) {
  -webkit-box-shadow: $color $x_offset $y_offset $blur_size;
  -moz-box-shadow: $color $x_offset $y_offset $blur_size;
  box-shadow: $color $x_offset $y_offset $blur_size;
}

@mixin word-wrap-all() {
  -ms-word-break: break-all;
  word-break: break-all;
  // Non standard for webkit
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

@mixin focus($r: 82, $g: 168, $b: 236) {
  border-color: rgba($r, $g, $b, 0.8);
  /* IE6-9 */
  //-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba($r, $g, $b, 0.6);
  //   -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba($r, $g, $b, 0.6);
  //        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba($r, $g, $b, 0.6);
}

@mixin linear-gradient($fromColor, $toColor) {

  background: $fromColor;
  background: -moz-linear-gradient(left,  $fromColor 0%, $toColor 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%,$fromColor), color-stop(100%,$toColor));
  background: -webkit-linear-gradient(left,  $fromColor 0%,$toColor 100%);
  background: -o-linear-gradient(left,  $fromColor 0%,$toColor 100%);
  background: -ms-linear-gradient(left,  $fromColor 0%,$toColor 100%);
  background: linear-gradient(to right,  $fromColor 0%,$toColor 100%);
  //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='{$fromColor}', endColorstr='{$toColor}',GradientType=1);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#{$fromColor}', EndColorStr='#{$toColor}');
  //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#7db9e8',GradientType=0 ); /* IE6-9 */
}

@mixin at2x($path, $ext: "jpg", $w: auto, $h: auto) {
  $at1x_path: "#{$path}.#{$ext}";
  $at2x_path: "#{$path}@2x.#{$ext}";

  background-image: url("#{$at1x_path}");

  @media all and (-webkit-min-device-pixel-ratio : 1.5),
  all and (-o-min-device-pixel-ratio: 3/2),
  all and (min--moz-device-pixel-ratio: 1.5),
  all and (min-device-pixel-ratio: 1.5) {
    background-image: url("#{$at2x_path}");
    background-size: $w $h;
  }
}

@function em($target, $context: $basic_font_size) {
  @return ($target / $context) * 1em;
}

@mixin center($columns, $context: $total-columns) {
  width: columns($columns, $context);
  margin: 0 auto;
}

@mixin border-box() {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

@mixin padding-box() {
  -webkit-box-sizing: padding-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: padding-box;    /* Firefox, other Gecko */
  box-sizing: padding-box;        /* Opera/IE 8+ */
}

@mixin content-box() {
  -webkit-box-sizing: content-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: content-box;    /* Firefox, other Gecko */
  box-sizing: content-box;         /* Opera/IE 8+ */
}

//@include border-box-sizing;
// *, *::before, *::after {
//   -moz-box-sizing: border-box;
//   -webkit-box-sizing: border-box;
//   box-sizing: border-box;
// }

@mixin border-box-sizing2 {
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

@mixin clearfix() {
  // &:after {
  //   content: ".";
  //   display: block;
  //   height: 0;
  //   clear: both;
  //   visibility: hidden;
  // }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin responsive-img() {
  max-width: 100%;
  width: 100%;
  height: auto;
  display: block;
}

@mixin vertical-align() {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transform: translate(-50%,-50%); /* Safari */
}

@mixin image-shadows($r: 255, $g: 255, $b: 255, $top: 0px) {
  &:before, &:after {
    display: none;
    content: '';
    width: 100px;
    height: 100%;
    z-index: 200;
    position: absolute;
    display: block;
    clear: both;
    top: $top;
  }
  &:before {
    content: "";
    left: 0;
    background: -moz-linear-gradient(left,  rgba($r,$g,$b,1) 0%, rgba($r,$g,$b,0) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba($r,$g,$b,1)), color-stop(100%,rgba($r,$g,$b,0))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left,  rgba($r,$g,$b,1) 0%,rgba($r,$g,$b,0) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left,  rgba($r,$g,$b,1) 0%,rgba($r,$g,$b,0) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left,  rgba($r,$g,$b,1) 0%,rgba($r,$g,$b,0) 100%); /* IE10+ */
    background: linear-gradient(to right,  rgba($r,$g,$b,1) 0%,rgba($r,$g,$b,0) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#efece0', endColorstr='#03efece0',GradientType=1 ); /* IE6-9 */
  }
  &:after {
    right: 0;
    background: -moz-linear-gradient(left,  rgba($r,$g,$b,0) 0%, rgba($r,$g,$b,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba($r,$g,$b,0)), color-stop(100%,rgba($r,$g,$b,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left,  rgba($r,$g,$b,0) 0%,rgba($r,$g,$b,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left,  rgba($r,$g,$b,0) 0%,rgba($r,$g,$b,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left,  rgba($r,$g,$b,0) 0%,rgba($r,$g,$b,1) 100%); /* IE10+ */
    background: linear-gradient(to right,  rgba($r,$g,$b,0) 0%,rgba($r,$g,$b,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#03efece0', endColorstr='#efece0',GradientType=1 ); /* IE6-9 */
  }
}

@mixin display-flex() {
  display: -webkit-box;           /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;              /* OLD - Firefox 19- (doesn't work very well) */
  display: -ms-flexbox;           /* TWEENER - IE 10 */
  display: -webkit-flex;          /* NEW - Chrome */
  display: flex;                  /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

@mixin display-flex-wrap() {
  display: -webkit-flex; /* Safari */
  -webkit-flex-wrap: wrap; /* Safari 6.1+ */
  display: flex;
  flex-wrap: wrap;
}

@mixin flex_order($value: 1) {
  -moz-box-flex: $value;
  -webkit-box-ordinal-group: $value;
  -moz-box-ordinal-group: $value;
  -ms-flex-order: $value;
  -webkit-order: $value;
  order: $value;
}

@mixin flexreset() {
  -webkit-box-flex: 0 0 auto;
  -moz-box-flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-ordinal-group: 0;
  -moz-box-ordinal-group: 0;
  -ms-flex-order: 0;
  -webkit-order: 0;
  order: 0;
}

@mixin order($order: 1) {
  -webkit-box-ordinal-group: $order;
  -moz-box-ordinal-group: $order;
  -ms-flex-order: $order;
  -webkit-order: $order;
  order: $order;
}

@mixin placeholder_color($color: #4e4e4e) {
  *::-webkit-input-placeholder {
    color: $color;
    opacity: 1;
  }
  *:-moz-placeholder {
    /* FF 4-18 */
    color: $color;
    opacity: 1;
  }
  *::-moz-placeholder {
    /* FF 19+ */
    color: $color;
    opacity: 1;
  }
  *:-ms-input-placeholder {
    /* IE 10+ */
    color: $color;
  }
  * {
    opacity: 1;
  }
}

@mixin flexCenter($direction: column) {
  @include display-flex;
  flex-direction: $direction;
  align-items: center;
  align-content: center;
  justify-content: center;

  -ms-flex-direction: $direction;
  -ms-flex-align: center;
  -ms-flex-pack: center;
}

@mixin opactity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

// generic transform
@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  -o-transform: $transforms;
  transform: $transforms;
}

@mixin rotate($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -ms-transform: rotate($deg);
  -o-transform: rotate($deg);
  transform: rotate($deg);
}

/* MIXINS FOR ZOOM IMG - WORKS PAGE */
@mixin transition-zoom-img($property: all, $duration: 1.4s, $function: ease) {
  -webkit-transition: $property $duration $function;
  -moz-transition: $property $duration $function;
  -ms-transition: $property $duration $function;
  -o-transition: $property $duration $function;
  transition: $property $duration $function;
}
@mixin transform-zoom-img($scale) {
  transform: $scale;
  -ms-transform: $scale; /* IE 9 */
  -moz-transform: $scale; /* Firefox */
  -webkit-transform: $scale; /* Safari and Chrome */
  -o-transform: $scale; /* Opera */
}

@mixin transform-reset() {
  transform: none;
  -ms-transform: none;
  -moz-transform: none;
  -webkit-transform: none;
  -o-transform: none;
}

@mixin writing-mode($property: horizontal-tb) {
  writing-mode: $property;
  -webkit-writing-mode: $property;
  -ms-writing-mode: $property;
}

@function rgba-to-rgb($rgba, $background: #fff) {
  @return mix(rgb(red($rgba), green($rgba), blue($rgba)), $background, alpha($rgba) * 100%)
}

@mixin beforeAfterColor($color: inherit){
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: $color;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background-color: $color;
  }
}


@mixin backgroundCover() {
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
