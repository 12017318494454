@import "formSummary";
@import "transitions";

#gettingToKnowYou {
  margin: 0 auto 50px auto;
  overflow: hidden;
  min-height: 60vh;
}

.interactive-form-wrapper {
  margin: 0 auto 50px auto;
  padding-top: 100px;
  @media(max-width: 1080px) {
    padding-top: 30px;
  }
}

.section-title {
  max-width: 100%;
  margin: 0 0 20px 0;
}

.auto-save-component {
  margin: 0 0 10px 0;
  text-align: right;
  font-size: 10px;
  color: #D8D8D8;
  @media (max-width: 1080px) {
    max-width: 90%;
    margin: 0 auto 10px auto;
  }
}

.form-pager {
  border-top: 3px solid #0A5F73;
  width: 100%;
  position: relative;
  @media (max-width: 1080px) {
    //display: none;
    max-width: 90%;
    margin: 0 auto;
  }
  .form-pager-items {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    text-align: left;
    padding: 20px 0 20px 0;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
    span {
      display: block;
      padding: 0 10px;
      white-space: pre-wrap;
      @media(max-width: 700px) {
        display: none;
      }
      &.is-accessible {
        cursor: pointer;
      }
      &.is-not-accessible {
        color: #D8D8D8;
      }
      &.active {
        font-weight: 700;
        cursor: text;
        color: #0a5f73;
        @media(max-width: 700px) {
          display: block;
          margin: 0 auto;
          text-align: center;
        }
      }
    }
    &.app-is-fetching-true {
      span {
        color: #D8D8D8;
        cursor: text;
      }
    }
  }
  .form-pager-indicator {
    display: block;
    position: absolute;
    height: 10px;
    top: -7px;
    left: 0;
    background: $main_yellow;
    transition: all .2s linear;
  }
  .swiper-button-prev, .swiper-button-next {
    background-repeat: no-repeat;
    background-size: 15px 30px;
    background-position: center;
    position: absolute;
    top: 20px;
    width: 40px;
    height: 40px;
    display: none;
    &.button-disabled, &.accessible-false {
      opacity: 0.35;
    }
    @media(max-width: 700px) {
      display: block;
    }
  }
  .swiper-button-prev {
    background-image: url('../svg/swiper/arrow_back.svg');
    left: 0;
  }
  .swiper-button-next {
    background-image: url('../svg/swiper/arrow_forward.svg');
    right: 0;
  }
}


.section-container-wrapper {
  position: relative;
}

.section-container-spending-summary {
  margin-top: 40px;
}
.section-container-summary {
  margin-top: 40px;
}
.all-steps-validation {
  max-width: 680px;
  width: 100%;
  margin: 0 auto 40px auto;
  float: none;
  text-align: center;
  .all-steps-validation-error {
    margin: 0 0 20px 0;
  }
}

.form-step-wrapper {
  max-width: 100%;
  padding: 40px 20px;
  margin: 0 auto;
  position: relative;
  .form-step-info {
    max-width: 530px;
    width: 100%;
    margin: 0 auto 40px auto;
    float: none;
  }
  .form-step-header {
    text-align: center;
    white-space: pre-wrap;
  }
  .expand-icon {
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-image: url('../../images/svg/info-icon.svg') !important;
    display: inline-block;
    margin: 0 0 -2px 10px;
    cursor: pointer;
    transition: all 0.5s;
    &.expand-icon-visible-true {
      background-image: url('../../images/svg/close-icon.svg') !important;
    }
  }
}

.description-text {
  transition: all 0.5s;
  overflow: hidden;
  max-height: 0;
  &.description-text-visible-true {
    max-height: 200px;
  }
}

form.interactive-form.portal-form {

  .form-rows {
    max-width: 480px;
    width: 100%;
    margin: 0 auto;
    float: none;
    .form-row {
      float: none !important;
      width: 100%;
      label {
        float: none !important;
      }
      input {
        float: none !important;
      }
      &.element-error {
        .error-inline {
          max-height: 50px;
          opacity: 1;
        }
      }
      &.element-success {
        .error-inline {
          max-height: 0;
          opacity: 0;
        }
      }
    }
  }
  .form-actions {
    float: none;
    display: flex;
    justify-content: space-between;
    max-width: 680px;
    margin: 60px auto 0 auto;
    .button {
      flex: 1;
      margin: 0 20px;
    }
  }
  .error-inline {
    float: none;
    display: block;
    max-height: 0;
    opacity: 0;
    transition: all 0.3s linear;
  }
}

.step-error {
  text-align: center;
  color: #D0021B;
  max-width: 700px;
  margin: 0 auto;
}

.initial-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  padding-top: 50px;
}

.welcome-message {

}
