:focus {
  outline: none;
}

.visually-hidden {
  display: none;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin: 0 0 1.2em 0;
  font-weight: 300;
  line-height: 1.2;
  color: inherit;
  font-family: $fontDefault;
}

h1, .h1 {
  font-size: 60px;
  font-weight: 300;
  color: $main_text_colour;
  @media(max-width: 767px) {
    font-size: 27px;
  }
}

h2, .h2 {
  font-size: 30px;
  font-weight: 500;
  @media(max-width: 767px) {
    font-size: 20px;
  }
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 18px;
  font-weight: 700;
  @media(max-width: 767px) {
    font-size: 16px;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  font-size: 25px;
  margin-top: 0;
  margin-bottom: 1.2em;
  line-height: 1.4em;
  letter-spacing: normal;
  font-weight: 300;
  @media(max-width: 767px) {
    font-size: 20px;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

p.tiny {
  font-size: 18px;
  @media(max-width: 767px) {
    font-size: 16px;
  }
}

p.small {
  font-size: 25px;
  @media(max-width: 767px) {
    font-size: 20px;
  }
}

p.lead {
  font-size: 25px;
  @media(max-width: 767px) {
    font-size: 20px;
  }
}

.text-align-center {
  text-align: center;
}

a {
  color: $main_text_colour;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

b, strong {
  font-weight: 700;
}

ul {
  padding-inline-start: 17px;
  li {

  }
}
