@import "gettingToKnowYou";
@import "whoAmI";
@import "datePicker";

.alert {
  font-size: 18px;
  &.alert-danger {
    color: #D0021B;
  }
}

.password-reset-link {
  text-decoration: underline;
  display: block;
  margin-bottom: 30px;
}

form.portal-form {
  .form-errors {
    @include full;
    margin: 10px 0;
    color: #D0021B;
    font-size: 18px;
  }
  .error-inline, label.error, span.error {
    @include full;
    color: #D0021B;
    margin: 5px 0 0 0;
    font-size: 18px;
    @media (max-width: 600px) {
      font-size: 15px;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }
  .form-rows {
    @include full;
    margin: 10px 0;
    .form-row {
      @include full;
      margin: 0 0 30px 0;
      .expand-icon {
        width: 24px;
        height: 24px;
        background-size: 24px 24px;
        background-repeat: no-repeat;
        background-image: url('../../images/svg/info-icon.svg') !important;
        display: inline-block;
        margin: 0 0 -2px 10px;
        cursor: pointer;
        transition: all 0.5s;
        &.expand-icon-visible-true {
          background-image: url('../../images/svg/close-icon.svg') !important;
        }
      }
      .help-text {
        font-size: 16px;
        max-height: 0;
        transition: all 0.5s;
        overflow: hidden;
        margin: 0 0 5px 0;
        &.help-text-visible-true {
          max-height: 200px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      .previous-submission-value {
        margin-top: 10px;
        h4 {
          margin: 0 0 5px 0;
          cursor: pointer;
        }
        p {

        }
        .expand-icon {
          margin-bottom: -6px;
        }
        .additional-info {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        }
        &.is-active-true {
          .additional-info {
            max-height: 800px;
            transition: max-height 1s ease-in-out;
          }
          .expand-icon {
            background-image: url('../../images/svg/close-icon.svg') !important;
          }
        }
      }
      &.row-type-text {
        label {
          @include full;
          font-size: 30px;
          margin-bottom: 10px;
          white-space: pre-wrap;
          @media (max-width: 600px) {
            font-size: 18px;
          }
        }
        input {
          @include full;
          font-size: 25px;
          padding: 15px 28px 15px 15px;
          border: none;
          border-bottom: 3px solid $main_yellow;
          @media (max-width: 600px) {
            font-size: 18px;
          }
          &.error {

          }
        }
      }
      &.row-type-select {
        label {
          @include full;
          float: none;
          font-size: 30px;
          margin-bottom: 10px;
          display: block;
        }
        &.element-success, &.element-error {
          >div>div {
            background-repeat: no-repeat;
            background-position: 85% 19px;
            cursor: auto;
            background-attachment: scroll;
          }
        }
        &.element-success {
          >div>div {
            background-image: url('../../images/svg/check-symbol.svg') !important;
            background-size: 20px 16px !important;
          }
        }
        &.element-error {
          >div>div {
            background-image: url('../../images/svg/x-symbol.png') !important;
            background-size: 14px 14px !important;
          }
        }
      }
      &.row-type-checkbox {
        input {
          //position: relative;
          //top: -2px;
          //margin-right: 5px;
        }
      }
      &.row-type-checkbox {
        >label {
          font-size: 20px;
          font-weight: 500;
          @media (max-width: 600px) {
            font-size: 18px;
          }
        }
        .checkbox-wrapper {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          max-width: 250px;
          input.radio-button {
            width: auto;
          }
          span {
            position: relative;
            bottom: -2px;
          }
          .single-checkbox {

          }
        }
      }
      &.row-type-multiple {
        .row-type-multiple-inner {
          margin-bottom: 30px;
          >div{
            padding: 40px 0 40px 0;
            margin: 0;
            position: relative;
            //&:first-child {
            //  padding-top: 0;
            //}
            &:last-child {
              margin-bottom: 0;
              border-bottom: none;
            }

            &:after {
              content: "";
              display: block;
              position: absolute;
              left: -25%;
              bottom: 0;
              width: 150%;
              border-top: 3px solid $main_yellow;
              background: #f8f7f7;
              z-index: -1;
              height: 100%;
            }

            &:nth-child(2n) {
              padding: 40px 0 40px 0;
              &:after {
                content: "";
                display: block;
                position: absolute;
                left: -25%;
                bottom: 0;
                width: 150%;
                border-top: 3px solid $main_yellow;
                background: #e2eeee;
                z-index: -1;
                height: 100%;
              }
            }
            .remove-nested-item {
              position: absolute;
              right: -100px;
              top: 15px;
              cursor: pointer;
              padding: 5px;
              border: 2px solid #00a54a;
              color: #fff;
              background: #00a54a;
              width: 24px;
              height: 24px;
              text-align: center;
              border-radius: 15px;
              display: block;
              line-height: 9px;
              @media(max-width: 768px) {
                right: 0;
              }
            }
          }
          &.row-type-multiple-inner-bottom {
            margin-top: 30px;
          }
        }
        >h3 {
          margin-bottom: 10px;
        }
        >p {
          font-size: 18px;
        }
      }
      &.row-type-textarea {
        textarea {
          width: 100%;
          height: 205px;
          resize: none;
          padding: 15px;
          border: 3px solid transparent;
          font-size: 25px;
          // http://stripesgenerator.com/
          background-image: linear-gradient(180deg, #ffffff 34.25%, #f7b133 34.25%, #f7b133 36.30%, #fff 36.30%, #fff 50%, #ffffff 50%, #ffffff 84.25%, #f7b133 84.25%, #f7b133 86.30%, #fff 86.30%, #fff 100%);
          background-size: 146.00px 146.00px;
          border-top: 3px solid #fff;
          &:focus, &.textarea-has-value {
            border: 3px solid $main_yellow;
            background: none;
          }
        }
      }
      &.row-type-currency {
        position: relative;
        .pound {
          display: block;
          position: absolute;
          left: 15px;
          bottom: 15px;
          font-size: 25px;
          color: #D8D8D8;
        }
        .input-wrapper {
          position: relative;
        }
        input {
          padding-left: 40px;
        }
        &.has-value-true {
          .pound {
            color: $main_text_colour;
          }
        }
        .additional-info {
          font-weight: 500;
          margin-bottom: 5px;
          margin-top: 5px;
        }
      }
      &.element-success, &.element-error {
        input {
          background-repeat: no-repeat;
          background-position: 98% 50%;
          cursor: auto;
          background-attachment: scroll;
        }
      }
      &.element-success {
        input {
          background-image: url('../../images/svg/check-symbol.svg') !important;
          background-size: 20px 16px !important;
        }
      }
      &.element-error {
        input {
          background-image: url('../../images/svg/x-symbol.png') !important;
          background-size: 14px 14px !important;
        }
      }
    }
  }
  .form-actions {
    @include full;
    margin: 40px 0 10px 0;
    .hidden {
      display: none;
    }
  }

  // Placeholders
  $placeholderColor: #D8D8D8;
  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $placeholderColor;
    font-size: 18px;
    position: relative;
    top: -3px;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $placeholderColor;
    opacity: 1;
    font-size: 18px;
    vertical-align: middle;
    position: relative;
    top: -3px;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $placeholderColor;
    opacity: 1;
    font-size: 18px;
    position: relative;
    top: -3px;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $placeholderColor;
    font-size: 18px;
    position: relative;
    top: -3px;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $placeholderColor;
    font-size: 18px;
    position: relative;
    top: -3px;
  }
  ::placeholder { /* Most modern browsers support this now. */
    color: $placeholderColor;
    font-size: 18px;
    position: relative;
    top: -3px;
  }

  input[type="password"] {
    -moz-appearance:    textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
  }
}

.form-wrapper-centered {
  @include span(6 of 12 last);
  margin: 0 auto 60px auto;
}

// Registration
.ref-number-home-page {
  >label {
    width: auto !important;
  }
  .letter-info-icon-container {
    position: relative;
    float: left;
    margin-top: 9px;
    @media(max-width: 500px) {
      margin-top: 0;
    }
    .letter-info-icon {
      width: 24px;
      height: 24px;
      background-size: 24px 24px;
      background-repeat: no-repeat;
      background-image: url('../../images/svg/info-icon.svg') !important;
      display: inline-block;
      margin: 0 0 -2px 10px;
      cursor: pointer;
      transition: all 0.5s;
    }
    .letter-info-icon-container-img {
      z-index: 3;
      display: none;
      position: absolute;
      top: -70px;
      left: -10px;
      border: 2px solid $main_yellow;
      @media(max-width: 500px) {
        left: -130px;
      }
      @media(max-width: 340px) {
        left: -140px;
      }
      img.letter-img {
        display: block;
        @media(max-width: 1100px) {
          max-width: 400px;
        }
        @media(max-width: 500px) {
          max-width: 280px;
        }
        @media(max-width: 340px) {
          max-width: 240px;
        }
        //max-width: 100%;
        //height: auto;
      }
    }
    &:hover {
      .letter-info-icon-container-img {
        display: block;
      }
    }
  }
}


//----- Page specific styles
.register-user {
  @include span(6 of 12);
  padding: 50px;
}

.register-reference-number {
  @include span(6 of 12);
  background: #F5F5F5;
  padding: 50px;
  @media(max-width: 1000px) {
    display: block;
    width: 100%;
    margin-right: 0;
  }
}

.login-form-wrapper {
  @include span(6 of 12 last);
  background: #fff;
  padding: 50px;
  @media(max-width: 1000px) {
    display: block;
    width: 100%;
    margin-right: 0;
  }
}

.password-requirements {
  @include span(6 of 12 last);
  padding: 50px;
  //margin-top: 310px;
  .password-requirements-inner {
    border-left: 2px solid $main_yellow;
    padding-left: 20px;
    max-width: 340px;
    ul {
      li {
        padding-right: 20px;
        &.checked {
          color: #77b259;
          background-image: url('../../images/svg/check-symbol.svg') !important;
          background-size: 20px 16px !important;
          background-repeat: no-repeat;
          background-position: 100% 0;
          cursor: auto;
          background-attachment: scroll;
        }
      }
    }
  }
}

// Checxboxes
input[type=checkbox].css-checkbox {
  position:absolute; z-index:-1000; left:-1000px; overflow: hidden; clip: rect(0 0 0 0); height:1px; width:1px; margin:-1px; padding:0; border:0;
}

input[type=checkbox].css-checkbox + label.css-label {
  padding-left:35px;
  padding-top: 2px;
  //height:27px;
  display:inline-block;
  background-repeat:no-repeat;
  background-position: 0 0;
  vertical-align:middle;
  cursor:pointer;
}

input[type=checkbox].css-checkbox:checked + label.css-label {
  background-image:url('../../images/forms/check-icon.png');
  @media (max-width: 600px) {
    background-image:url('../../images/forms/check-icon-mobile.png');
    line-height: 18px;
  }
}

label.css-label {
  background-image:url('../../images/forms/uncheck-icon.png');
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @media (max-width: 600px) {
    line-height: 18px;
    background-image:url('../../images/forms/uncheck-icon-mobile.png');
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.register-page-form {
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
    .password-requirements-inner {
      ul {
        font-size: 15px;
      }
    }
    .register-user {
      width: 100%;
      padding-bottom: 0;
      padding-top: 10px;
    }
    .password-requirements {
      width: 100%;
      .password-requirements-inner {
        max-width: 100%;
      }
    }
  }
}

form.resend-register-email-form {
  button.button-reset {
    width: auto;
  }
  button {
    width: auto;
    margin-bottom: 10px;
  }
  .form-rows {
    .form-row {
      &.row-type-textarea {
        textarea {
          width: 100%;
          height: 205px;
          resize: none;
          padding: 15px;
          font-size: 16px;
          border: 3px solid $main_yellow;
          background: none;
        }
      }
      .help-text {
        max-height: none;
      }
    }
  }
}

.password-reset-page {
  .password-reset-page-form{
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
      flex-direction: column;
      .password-requirements-inner {
        ul {
          font-size: 15px;
        }
      }
      .register-user {
        width: 100%;
        padding-bottom: 0;
        padding-top: 10px;
      }
      .password-requirements {
        width: 100%;
        .password-requirements-inner {
          max-width: 100%;
        }
      }
    }
  }
}

.alert-text {
  color: #d43934;
  font-size: 20px;
}



