#block-followus {
  width: 100%;
  background: #E2EEEE;
  padding: 19px 0;
  @media(max-width: 767px) {
    margin: 25px 0;
  }
  .block-content {
    @include container;
    .field--name-body {

    }
    .follow-us-contact {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-weight: 500;
      }
      span, a {
        margin-right: 38px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
