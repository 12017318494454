.paragraph--type--image-and-text {
  overflow: hidden;
  // IMAGE LEFT OR RIGHT
  &.image-position-right,  &.image-position-left {
    .paragraph-container {
      @include container;
      max-width: 960px;
      margin: 50px auto;
      position: relative;
      @media(max-width: 767px) {
        margin: 25px auto;
      }
      .paragraph-container-inner {
        border: 2px solid $main_yellow;
        //margin-left: span(1 of 12) + gutter();
        //margin-right: span(1 of 12) + gutter();
        padding-top: 50px;
        padding-bottom: 50px;
        position: relative;
        float: left;
        max-width: 100%;
        @media(max-width: 1023px) {
          padding: 40px 0;
          margin-left: $mobile_container_padding;
          margin-right: $mobile_container_padding;
        }
        @media(max-width: 767px) {
          width: 100%;
          border: none;
          margin: 0;
          padding: 0;
        }
      }
      .paragraph-content {
        position: relative;
        @include full;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        min-height: 495px;
        @media(max-width: 1023px) {
          flex-direction: column;
        }
        @media(max-width: 767px) {
          min-height: 0;
        }
      }
      @include nested(9 of 12) {
        .text-container {
          @include span(6 of 12);
          padding-left: span(1 of 12) + gutter();
          position: relative;
          z-index: 1;
          padding-top: 50px;
          padding-bottom: 50px;
          @media(max-width: 1023px) {
            @include full;
            padding: 30px $mobile_container_padding;
          }
          @media(max-width: 767px) {
            padding: 30px $mobile_container_padding 0 $mobile_container_padding;
            order: 2;
          }
          p {
            font-size: 18px;
            @media(max-width: 767px) {
              font-size: 16px;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
          .button {
            float: left;
            @media(max-width: 767px) {
              float: none;
              margin: 0 auto;
              display: block;
            }
          }
        }
      }
      .image-container {
        @include span(6 of 12 last);
        float: left;
        position: relative;
        z-index: 1;
        @media(max-width: 1023px) {
          @include full;
        }
        @media(max-width: 767px) {
          order: 1;
        }
        .field--name-field-image {
          background-image: none !important;
          @media(max-width: 1023px) {
            text-align: center;
          }
        }
        img {
          //padding: 10px;
          background: #f5f5f5;
          max-width: 550px;
          height: auto;
          @media(max-width: 1023px) {
            max-width: 100%;
          }
          @media(max-width: 767px) {
            width: 100%;
            height: auto;
            display: block;
            padding: 15px 0;
          }
        }
      }

      .paragraph-container-inner-bg {
        width: 100%;
        height: 100%;
        background: #f5f5f5;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        @media(max-width: 767px) {
          display: none;
        }
        &:before {
          content: "";
          width: 100%;
          top: 0;
          left: -100%;
          height: 100%;
          background: #f5f5f5;
          position: absolute;
        }
        &:after {
          content: "";
          width: 100%;
          top: 0;
          right: -100%;
          height: 100%;
          background: #f5f5f5;
          position: absolute;
        }
      }
    }
    &.image-offset-right {
      .paragraph-container .image-container img {
        position: relative;
        right: -40px;
        @media(max-width: 767px) {
          right: 0;
        }
      }
    }
    &.image-padding {
      .paragraph-container .image-container img {
        padding: 10px;
        @media(max-width: 767px) {
          padding: 0;
        }
      }
    }
  }
  // IMAGE OVER
  &.image-position-over {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
    @media(max-width: 767px) {
      padding-top: 25px;
      padding-bottom: 25px;
    }
    .paragraph-content {
      @media(max-width: 1023px) {
        display: flex;
        flex-direction: column;
      }
    }
    .image-container {
      @media(max-width: 1023px) {
        @include full;
        order: 1;
        margin-bottom: 30px;
      }
      @media(max-width: 767px) {
        margin-bottom: 25px;
      }
      .field--name-field-image {
        width: 100%;
        position: relative;
        height: 590px;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        @media(max-width: 1023px) {
          height: 390px;
        }
        @media(max-width: 767px) {
          height: 195px;
        }
        img {
          display: none;
        }
      }
    }
    .text-container {
      @include container;
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 3;
      text-align: center;
      justify-content: center;
      @media(max-width: 1023px) {
        @include full;
        position: relative;
        order: 2;
      }
      .content {
        padding-left: span(1 of 12) + gutter();
        padding-right: span(1 of 12) + gutter();
        @media(max-width: 1023px) {
          @include full;
          padding: 0 $mobile_container_padding;
        }
      }
      .button {
        @media(max-width: 767px) {
          float: none;
          margin: 0 auto;
          display: block;
        }
      }
    }
    &.white-text {
      .text-container {
        color: #fff;
        @media(max-width: 1023px) {
          color: $main_text_colour;
        }
        @media(max-width: 767px) {
          color: $main_text_colour;
        }
      }
    }
    &.text-on-left {
      .text-container {
        @include container;
        text-align: left;
        justify-content: flex-start;
        .content {
          @include span(5 of 12);
          @media(max-width: 1023px) {
            @include full;
            padding: 0 $mobile_container_padding;
          }
          padding-right: 0;
          p {
            font-size: 18px;
          }
        }
      }
    }
  }
}




//.paragraph.paragraph--type--image-and-text {
//  position: relative;
//  .paragraph-content {
//    float: none;
//    width: 100%;
//    margin: 0 auto;
//    min-height: 595px;
//    display: flex;
//    flex-wrap: nowrap;
//    @media(max-width: 768px) {
//      display: block;
//      min-height: 0;
//    }
//  }
//  .image-overlay {
//    display: none;
//  }
//  > .container {
//    position: relative;
//    z-index: 2;
//    @media(max-width: 768px) {
//      padding: 0;
//    }
//  }
//  .image-container {
//    float: left;
//    display: block;
//    width: 50%;
//    margin-right: 0;
//    overflow: visible;
//    //overflow: hidden;
//    position: relative;
//    padding: 60px 0 90px;
//    .bg {
//      position: absolute;
//      z-index: -1;
//      width: 100%;
//      top: 0;
//      right: 0;
//      height: 100%;
//    }
//    .image-caption {
//      position: absolute;
//      bottom: 0;
//      left: 0;
//      z-index: 1;
//      font-size: 45px;
//      background: #fff;
//      padding: 15px 0;
//      height: 80%;
//      @include writing-mode(vertical-rl);
//      @include transform(rotate(180deg));
//      @media(max-width: 980px) {
//        font-size: 35px;
//      }
//    }
//    .field--name-field-image {
//      width: 100%;
//      display: block;
//      margin: 0;
//      position: relative;
//      -webkit-background-size: cover;
//      -moz-background-size: cover;
//      -o-background-size: cover;
//      background-size: cover;
//      background-repeat: no-repeat;
//      background-position: 50% 50%;
//      height: 100%;
//      //min-height: 600px;
//      left: 100px;
//      right: auto;
//      //transform: translateX(100px);
//    }
//    img {
//      max-width: 100%;
//      height: auto;
//      display: none;
//    }
//  }
//  .text-container {
//    float: left;
//    width: 50%;
//    margin-right: 0;
//    padding: 140px 0 40px 90px;
//    display: flex;
//    align-items: center;
//    position: relative;
//    z-index: 10;
//    @include flex_order(2);
//    h1, h2 {
//      display: block;
//      text-align: left;
//      width: 180%;
//      //margin-right: -50px;
//      //text-align: right;
//      @media(max-width: 868px) {
//        width: auto;
//      }
//    }
//    h2 {
//      width: 140%;
//    }
//    .field--name-field-text {
//      width: span(73% );
//      float: right;
//    }
//    .content {
//      padding-left: 0;
//      padding-right: 19.622%;
//      width: 100%;
//      direction: rtl;
//      .field--name-field-text {
//        direction: ltr;
//      }
//    }
//  }
//
//  @media(max-width: 868px) {
//    .image-container {
//      .field--name-field-image {
//        left: 60px;
//      }
//    }
//    .text-container {
//      padding: 140px 0 40px 0;
//      .content {
//        padding-right: 0;
//      }
//    }
//  }
//
//  @media(max-width: 768px) {
//    .image-container {
//      width: 100%;
//      .field--name-field-image {
//        background: 0 none !important;
//        left: auto;
//        right: 0;
//        float: right;
//        img {
//          display: block;
//          //max-width: 100%;
//          max-width: 650px;
//          height: auto;
//          float: right;
//          padding-left: 60px;
//        }
//      }
//      .bg {
//        width: 90%;
//        right: 10%;
//      }
//    }
//    .text-container {
//      width: 100%;
//      padding: 40px 20px 0 span(1) + gutter();
//      h1, h2 {
//        width: 80%;
//        //margin-right: 0;
//      }
//      .content {
//        direction: ltr;
//      }
//      .field--name-field-text {
//        float: left;
//      }
//    }
//  }
//
//  @media(max-width: 650px) {
//    .image-container {
//      .field--name-field-image {
//        img {
//          max-width: 100%;
//        }
//      }
//    }
//  }
//
//  @media(max-width: 640px) {
//    .text-container {
//      h1, h2 {
//        width: 100%;
//        word-wrap: break-word;
//      }
//      .field--name-field-text {
//        width: 100%;
//      }
//    }
//    .image-container {
//      padding: 40px 0 60px;
//    }
//  }
//
//  &.image-position-right {
//    .paragraph-content {
//      justify-content: flex-end;
//    }
//    .image-container {
//      order: 2;
//      .field--name-field-image {
//        right: 100px;
//        left: auto;
//      }
//    }
//    .text-container {
//      order: 1;
//      padding: 80px 90px 80px 0;
//      h1, h2 {
//        //margin-right: 0;
//        text-align: left;
//      }
//      .field--name-field-text {
//        float: left;
//      }
//      .content {
//        padding-right: 0;
//        padding-left: 19.622%;
//        width: 100%;
//        direction: ltr;
//      }
//    }
//
//    @media(max-width: 868px) {
//      .image-container {
//        .field--name-field-image {
//          right: 60px;
//        }
//      }
//      .text-container {
//        padding-right: 0;
//        .content {
//          padding-left: 0;
//        }
//      }
//    }
//
//    @media(max-width: 768px) {
//      .image-container {
//        .field--name-field-image {
//          right: 0;
//          float: left;
//          img {
//            float: left;
//            padding-left: 0;
//            padding-right: 60px;
//          }
//        }
//        .bg {
//          right: auto;
//          left: 10%;
//        }
//      }
//      .text-container {
//        padding: 40px 20px 0 span(1) + gutter();
//      }
//    }
//
//  }
//}
//
////slides
//
//.paragraph.paragraph--type--image-and-text {
//  .image-container {
//    .slider-container {
//      width: 100%;
//      display: block;
//      margin: 0;
//      position: relative;
//      height: 100%;
//      .slider-wrapper {
//        height: 100%;
//        .slick-list {
//          height: 100%;
//        }
//        .slick-track {
//          height: 100%;
//        }
//      }
//    }
//  }
//}
//
//.paragraph.pg-residential-sales {
//  .paragraph-content {
//    @media(max-width: 980px) {
//      .text-container .content {
//        padding-left: 20px;
//      }
//    }
//    @media(max-width: 760px) {
//      float: left;
//      width: 100%;
//      .image-container {
//        padding-bottom: 0;
//      }
//    }
//    @media(max-width: 360px) {
//      .text-container .content {
//        padding-left: 0;
//      }
//    }
//    .kb-button {
//      &:hover {
//        color: #33638D;
//      }
//    }
//  }
//  @media(max-width: 980px) {
//    .container {
//      padding: 0;
//    }
//  }
//}
//
//.field--name-field-paragraphs > .field__item:last-child .paragraph.pg-residential-sales {
//  padding-bottom: 0;
//}
//
//.paragraph.paragraph--graphics {
//  margin-bottom: 60px;
//  .title {
//    text-align: right;
//    padding-right: 20px;
//    @media(max-width: 768px) {
//      padding: 40px 20px 0 span(1) + gutter();
//      //text-align: left;
//    }
//    @media(max-width: 380px) {
//      padding: 0 20px;
//    }
//  }
//  .paragraph-content {
//    background-color: #dbdbdb;
//    position: relative;
//    &:after {
//      position: absolute;
//      bottom: 0;
//      left: 0;
//      height: 40px;
//      width: 100%;
//      content: "";
//    }
//    .graphics-container {
//      @include span(8 of 12);
//      overflow: visible;
//      //overflow: hidden;
//      position: relative;
//      z-index: 2;
//      top: 60px;
//      padding: 0;
//      .bg {
//        width: 100%;
//        height: 100%;
//        background-color: #0E3755;
//        position: absolute;
//        top: 0;
//        left: 0;
//      }
//      .views-element-container {
//        position: relative;
//        top: 0;
//        left: 0;
//        color: #fff;
//        overflow: hidden;
//        padding: 40px 0;
//      }
//      .view-content {
//        padding: 30px 40px;
//        article.term--type-stats {
//          float: left;
//          margin-bottom: 50px;
//          text-align: center;
//          &.term--display--icon {
//            width: 33.33%;
//          }
//          &.term--display--circle {
//            width: 50%;
//          }
//          &:nth-child(3) {
//            float: right;
//          }
//          &:nth-child(4) {
//            clear: both;
//          }
//          @media(max-width: 1480px) {
//            padding: 0 30px;
//          }
//          @media(max-width: 1380px) {
//            &:nth-child(1), &:nth-child(2) {
//              width: 50%;
//            }
//            &:nth-child(3) {
//              clear: both;
//              float: left;
//              width: 100%;
//              padding: 0 33.33%
//            }
//          }
//          @media(max-width: 480px) {
//            width: 100% !important;
//            padding: 0 20% !important;
//          }
//        }
//      }
//      @media(max-width: 768px) {
//        width: 100%;
//        position: relative;
//        margin: 0;
//        float: none;
//        top: 0;
//      }
//    }
//    .text-container {
//      @include span(4 of 12 last);
//      padding: 0;
//      display: flex;
//      align-items: center;
//      position: relative;
//      z-index: 10;
//      @include flex_order(2);
//      h1, h2 {
//        width: 300%;
//        margin-right: -50px;
//      }
//      .content {
//        padding-right: span(1 of 4);
//      }
//      .field--name-field-text {
//        width: 100%;
//      }
//      @media(max-width: 768px) {
//        display: block;
//        width: 100%;
//        padding: 40px 20px 0 span(1) + gutter();
//      }
//    }
//  }
//}
//
//.paragraph.pg-catchment-area {
//  margin-bottom: 60px;
//  > h3 {
//    text-align: center;
//  }
//  .paragraph-content {
//    background-color: #f2f2f2;
//    position: relative;
//    min-height: 0;
//
//    .map-container {
//      @include span(8 of 12 last);
//      @include flex_order(2);
//      .field--name-field-image {
//        width: 100%;
//        display: block;
//        margin: 0;
//        position: relative;
//        -webkit-background-size: cover;
//        -moz-background-size: cover;
//        -o-background-size: cover;
//        background-size: cover;
//        background-repeat: no-repeat;
//        background-position: 50% 50%;
//        height: 100%;
//        min-height: 870px;
//        img {
//          max-width: 100%;
//          height: auto;
//          display: none;
//        }
//      }
//    }
//
//    @media(max-width: 1180px) {
//      .map-container {
//        width: 100%;
//        .field--name-field-image {
//          background: transparent none !important;
//          min-height: 0;
//          img {
//            display: block;
//          }
//        }
//      }
//    }
//
//    @media(max-width: 1024px) {
//      display: block;
//      .map-container {
//        display: block;
//      }
//    }
//
//    .text-container {
//      @include span(4 of 12);
//      width: span(4 of 12) + gutter();
//      @include flex_order(1);
//      margin-right: 0;
//      margin-top: 140px;
//      padding-top: 60px;
//      display: block; //no flex
//      padding-left: 8.57143%;
//      padding-right: 40px;
//      .content {
//        padding: 0;
//        .field--name-field-text {
//          width: 100%;
//        }
//      }
//    }
//
//    @media(max-width: 1180px) {
//      .text-container {
//        width: 100%;
//        margin: 0;
//        padding-bottom: 0;
//      }
//    }
//
//    @media(max-width: 1024px) {
//      .text-container {
//        display: block;
//      }
//    }
//  }
//}
//
