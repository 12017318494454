@import "blocks/followus";

.vimeo-video {
  width: 100%;
  //margin-bottom: 50px;
  .player {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    iframe {
      left: 0;
      top: 0;
      height: 100% !important;
      width: 100% !important;
      position: absolute;
    }
  }
}
